<template>
    <Page class="relative">
        <Header
            :class="[
                `transition-[opacity,transform] delay-500 duration-500 ease-in-out `,
                videoLoaded
                    ? `translate-y-0 opacity-100`
                    : `-translate-y-5    opacity-0`,
            ]"
        />

        <div class="fixed inset-0 flex items-center justify-center bg-home">
            <div class="relative size-full">
                <video
                    ref="video"
                    src="/images/bg-video.mp4"
                    :class="[
                        `size-full object-cover transition-opacity duration-500`,
                        videoLoaded ? 'opacity-100' : 'opacity-0',
                    ]"
                    muted
                    autoplay
                    loop
                    playsinline
                />
                <div
                    class="absolute inset-0 bg-gradient-to-t from-[#0A0A0A] backdrop-blur-sm"
                />
            </div>
        </div>
        <div
            class="relative mt-28 flex w-full flex-col items-center gap-10 text-white"
        >
            <h1
                :class="[
                    `max-w-2xl text-center font-400 text-3xl transition-[opacity,transform] delay-300 duration-500 ease-in-out sm:text-5xl`,
                    videoLoaded
                        ? `translate-y-0 opacity-100`
                        : `translate-y-5 opacity-0`,
                ]"
            >
                Exclusive deals<br />at the best hotels.
            </h1>
            <div
                :class="[
                    `max-w-md text-center font-400 text-sm transition-opacity delay-1000 duration-500 ease-in sm:text-lg`,
                    videoLoaded ? `opacity-100` : `opacity-0`,
                ]"
            >
                Ascend is your ticket to unbeatable rates that are hidden from
                standard search engine results.
            </div>
            <div class="text-center text-xl">
                <div>
                    Interested? We're launching soon! Email us at
                    <a class="underline" href="mailto:help@ascend.travel"
                        >help@ascend.travel
                    </a>
                </div>
            </div>
            <div
                v-if="'magic' in query"
                :class="[
                    `flex w-full max-w-[345px] flex-col gap-3 rounded-md border border-white/10 bg-white/10 px-5 py-4 backdrop-blur-sm transition-all delay-[2000ms] duration-500`,
                    videoLoaded
                        ? `translate-y-0 opacity-100`
                        : `translate-y-3 opacity-0`,
                ]"
            >
                <div class="font-600 text-sm">Welcome to {{ appName }}</div>
                <div class="font-400 text-sm">
                    You may have previously known us as Magic Travel. Ascend is
                    our new take on what the future of travel should look like.
                    And we want you to be among the first to try it!
                </div>
            </div>
        </div>
    </Page>
</template>

<script lang="ts" setup>
import Page from '~/components/Page.vue'
// import { Urls } from '~/utils/urls'

definePageMeta({
    layout: 'nothing',
})

// const { isAuthenticated } = useAuthentication()

const video = ref<HTMLVideoElement | null>(null)
const videoLoaded = ref(false)
const config = useRuntimeConfig()
const { query } = useRoute()

watch(video, (val) => {
    if (val) {
        if (!val.paused) {
            videoLoaded.value = true
        }
        val.onplay = () => {
            videoLoaded.value = true
        }
    }
})

const appName = config.public.appName
</script>
